import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@theme/services/toast.service';
import { BehaviorSubject, EMPTY, Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PublishService } from '../services/publish.service';
@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.scss'],
})
export class PublishComponent implements OnInit {
  @ViewChild('approveRejectPlugin')
  private approveRejectPlugin: TemplateRef<any>;
  @ViewChild('pluginAlreadyProcessed')
  private pluginAlreadyProcessed: TemplateRef<any>;

  public loading = false;
  public form: FormGroup = null;
  domains: any = [];
  allPlatforms: any = [];
  filteredPlatforms: any = [];
  currentUser: any = null;
  tags: any;
  matchedCompanies$ = new BehaviorSubject([]);
  companies: any = [];
  id: any = null;
  pluginDetail: any;
  stages: any = [
    { code: 'released', name: 'Released', selected: true },
    { code: 'under-development', name: 'Under Development' },
    { code: 'idea', name: 'Design Idea' },
  ];
  modalStatus: any;
  primaryContributorCompany: any = '';
  isPluginAlreadyProcessed: boolean = false;
  existingFileNames: string[] = [];
  allUsers: any = [];
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private publishService: PublishService,
    private userService: UserService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'] || null;
      this.loadData();
    });
    this.createForm();
    this.addFormChangesListener();
  }

  createForm() {
    this.form = this.fb.group({
      plugin_name: ['', [Validators.required, Validators.maxLength(256)]],
      plugin_owner: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(1024)]],
      visibility: ['Private', [Validators.required]],
      build4core: false,
      partnerplugin: false,
      back2community: true,
      stage: ['released'],
      release_date: [''],
      contributor: [''],
      domain: ['', Validators.required],
      platform: ['', Validators.required],
      tags: ['', [Validators.maxLength(1024)]],
      images: this.fb.array([]),
    });
  }

  addFormChangesListener() {
    this.form.get('domain').valueChanges.subscribe((domainNames: any) => {
      const selectedDomainTitles = domainNames
        ? Array.isArray(domainNames)
          ? domainNames
          : domainNames.split(',').map((title: string) => title.trim())
        : [];

      const selectedDomains = this.domains.filter((domain: any) =>
        selectedDomainTitles.includes(domain.name)
      );
      const platformDomainNames = selectedDomains.map((selectedDomain: any) => selectedDomain.name);
      this.filteredPlatforms = this.allPlatforms.filter((platform: any) =>
        platformDomainNames.includes(platform.domain_name)
      );
    });

    this.form.get('stage').valueChanges.subscribe(selectedStage => {
      if (selectedStage === 'under-development') {
        this.form.get('release_date').enable();
      } else {
        this.form.get('release_date').setValue(null);
        this.form.get('release_date').disable();
      }
    });
  }

  loadData() {
    this.loading = true;

    const dataObs$ = [this.getUserDetails(), this.publishService.getPublishData()];
    if (this.id) {
      // Only in case of plugin approver
      dataObs$.push(this.publishService.getPluginDetail(this.id));
    }
    forkJoin(dataObs$).subscribe(
      (res: any) => {
        this.loading = false;
        this.currentUser = res[0];
        const publishData = res[1];

        this.domains = publishData?.domains;
        this.allPlatforms = publishData?.platforms;
        this.tags = publishData?.tags;
        this.allUsers = (publishData?.users || []).filter(user => user && user.email);
        this.companies = publishData?.companies;

        if (this.id) {
          this.pluginDetail = res[2];
          this.isPluginAlreadyProcessed =
            ['approved', 'rejected'].indexOf(this.pluginDetail.plugin_status) !== -1;
          if (this.isPluginAlreadyProcessed) {
            this.showPluginAlreadyProcessedModal(this.pluginDetail);
          }

          this.updateForm(this.pluginDetail);
        } else {
          this.setDefaultPluginOwner(this.currentUser[0]);
        }
      },
      error => {
        this.loading = false;
        this.toastService.error(
          'Error',
          'There were some errors while loading data. Please contact support if problem persists.'
        );
      }
    );
  }

  getUserDetails(): any {
    this.userService.setCurrentUserInfo();
    const loggedInUser = this.userService.user;
    if (!loggedInUser) {
      return of(null);
    }

    return this.userService.getProfile(loggedInUser.email);
  }

  setDefaultPluginOwner(user: any) {
    if (user) {
      const email = user.email;
      this.allUsers.find((user: any) => user.email === email).selected = true;
      this.form.get('plugin_owner').setValue(email);
    }
  }

  back(): void {
    this.router.navigateByUrl('/');
  }

  tagsChanged(tags: any) {
    this.form.get('tags').setValue(tags);
  }

  finish() {
    this.loading = true;
    this.handleFileUpload()
      .pipe(
        switchMap(() => {
          return this.submitRequest();
        })
      )
      .subscribe(
        () => {},
        error => {
          this.loading = false;
          this.toastService.error('Error', 'There were some errors while uploading files.');
        }
      );
  }

  showPluginAlreadyProcessedModal(plugin: any) {
    this.modalStatus = plugin.plugin_status;
    const modalRef = this.modalService.open(this.pluginAlreadyProcessed, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-with-animation',
      size: 'm',
      centered: true,
      animation: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result
      .then(result => {
        if (result === 'ok') {
          this.router.navigateByUrl('/');
        }
      })
      .catch(() => {
        this.router.navigateByUrl('/');
      });
  }
  submitRequest() {
    const payload = { ...this.form.value };
    payload.project_name = payload.plugin_name;
    payload.domain = payload.domain.split(',');
    payload.plugin_owner = payload.plugin_owner.split(',');

    if (payload.release_date) {
      const { day, month, year } = payload.release_date;
      payload.release_date = new Date(year, month - 1, day);
    }

    if (payload.tags && payload.tags.length > 0) {
      payload.tags = payload.tags.map(tag => tag.display);
    }
    if (payload.images && payload.images.length > 0) {
      payload.images.forEach((image: any) => {
        image.image_url = `${payload.plugin_name}/${image.file.name}`;
        delete image.file;
      });
    }
    return this.publishService.getReviewURL().pipe(
      switchMap((reviewURL: any) => {
        payload.review_url = reviewURL.url;
        return this.publishService.submitRequest(payload).pipe(
          map((response: any) => {
            this.loading = false;
            this.toastService.success(
              'Request Submitted',
              'Your plugin publication request has been submitted. You will be notified upon approval.'
            );
            this.resetForm();
            setTimeout(() => this.router.navigateByUrl('/'), 500);
            return response;
          }),
          catchError((error: any) => {
            this.loading = false;
            let errorMessage = `Unable to publish product. Please try again later. Contact OEC Support if the problem persists.`;
            this.toastService.error('Error', errorMessage);
            return EMPTY;
          })
        );
      })
    );
  }

  resetForm() {
    const imagesFormArray = this.form.get('images') as FormArray;
    imagesFormArray.clear();
    const pluginOwnerValue = this.form.get('plugin_owner').value;
    this.primaryContributorCompany = null;
    this.form.reset();
    this.form.get('plugin_owner').setValue(pluginOwnerValue);
    this.form.get('visibility').setValue('Private');
    this.form.get('build4core').setValue(false);
    this.form.get('partnerplugin').setValue(false);
    this.form.get('back2community').setValue(true);
    this.form.get('stage').setValue('released');
  }
  onSearchCompany(value) {
    value = value.toLowerCase();
    const matched = this.companies.filter((i: any) => {
      return i.name.toLowerCase().indexOf(value) > -1;
    });
    this.matchedCompanies$.next(matched);
  }
  onContributorSelection(data: any) {
    this.form.get('contributor')?.markAllAsTouched();
    if (data && data.model) {
      this.form.get('contributor')?.setValue(data.model.name);
    } else {
      this.form.get('contributor')?.setValue(null);
    }
  }
  visibilityToolTip() {
    return `
      <b>Private</b>: Plugin is visible only to users within your company.<br>      
      <b>Public</b>: Plugin is accessible to everyone.<br>      
      <b>Protected</b>: Plugin is viewable exclusively by logged-in users.`;
  }
  contributionTypeToolTip() {
    return `
      <b>Build4Core</b>: This plugin will be part of core product in the future release.<br>      
      <b>Back2Community</b>: This plugin won't be part of core product and can be shared with community.<br>      
      <b>Partner Plugin</b>: This plugn is built by partners to provide additional functionality.`;
  }

  //For plugin approver.
  updateForm(data: any) {
    if (!data) {
      return;
    }
    const {
      domains,
      platform,
      stage,
      title,
      description,
      visibility,
      type,
      release_date,
      contributor,
      marketplace_image_v,
      marketplace_tag,
      plugin_owner_v,
    } = data;
    const parsedDomains = JSON.parse(domains) || [];
    const parsedDate = new Date(release_date);
    this.primaryContributorCompany = this.companies.find((com: any) => com.name === contributor);
    this.updateSelectionDomains(parsedDomains);
    this.updateSelectionPlatform(platform);
    this.updateSelectionStage(stage);
    this.updatePluginOwners(plugin_owner_v);
    this.form.patchValue({
      plugin_name: title || '',
      plugin_owner: plugin_owner_v.map((owner: any) => owner.email).join(',') || '',
      description: description || '',
      visibility: visibility || 'Private',
      build4core: type.includes('Build4Core'),
      partnerplugin: type.includes('PartnerPlugin'),
      back2community: type.includes('Back2Community'),
      stage: stage || '',
      release_date: release_date
        ? new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate())
        : null,

      contributor: contributor || '',
      domain: parsedDomains.map((d: any) => d.title).join(',') || '',
      platform: this.getPlatformCode(platform) || '',
      tags: this.transformTagsArray(marketplace_tag),
    });

    this.clearImagesFormArray();
    this.addImagesToFormArray(marketplace_image_v);
  }

  private getPlatformCode(platform: string): string {
    const selectedPlatform = this.allPlatforms.find((p: any) => p.name === platform);
    return selectedPlatform ? selectedPlatform.code : '';
  }

  updateSelectionDomains(data) {
    if (!data) return;
    this.domains.forEach(domainObj => {
      const matchingData = data.find(item => item.title === domainObj.name);
      if (matchingData) {
        domainObj.selected = true;
      }
    });
  }

  updateSelectionPlatform(platform: string) {
    if (!platform) return;
    this.allPlatforms.forEach(platformObj => {
      platformObj.selected = platformObj.name === platform;
    });
  }

  updateSelectionStage(selectedStage) {
    if (!selectedStage) return;
    this.stages = this.stages.map(stageObj => ({
      ...stageObj,
      selected: stageObj.code === selectedStage,
    }));
  }
  updatePluginOwners(owners) {
    if (!owners) return;
    this.allUsers.forEach(user => {
      const isOwner = owners.some(owner => owner.email === user.email);
      if (isOwner) {
        user.selected = true;
      }
    });
  }

  transformTagsArray(tagsArray) {
    return tagsArray.map(item => ({
      display: item.tag,
      value: item.tag,
    }));
  }
  approveRejectModal(status: any) {
    this.modalStatus = status;
    const modalRef = this.modalService.open(this.approveRejectPlugin, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-with-animation',
      size: 'm',
      centered: true,
      animation: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then(result => {
      if (result === 'confirm') {
        this.handleFileUpload().subscribe(
          () => {
            this.approve(status);
          },
          error => {
            this.loading = false;
            this.toastService.error('Error', 'There were some errors while uploading files.');
          }
        );
      }
    });
  }
  approve(action: any) {
    const payload = this.form.value;
    payload.project_name = payload.plugin_name;
    payload.domain = payload.domain.split(',');
    payload.plugin_owner = payload.plugin_owner.split(',');

    if (payload.release_date) {
      const { day, month, year } = payload.release_date;
      payload.release_date = new Date(year, month - 1, day);
    }

    if (payload.tags && payload.tags.length > 0) {
      payload.tags = payload.tags.map(tag => tag.display);
    }
    if (payload.images && payload.images.length > 0) {
      payload.images.forEach((image: any) => {
        if (image.file) image.image_url = `${payload.plugin_name}/${image.file.name}`;
        delete image.file;
      });
    }
    payload.action = action;
    payload.id = this.id;
    this.loading = true;
    this.publishService.approveRequest(payload).subscribe(
      (res: any) => {
        this.loading = false;
        this.form.reset();
        this.toastService.success(
          `Success`,
          `Plugin has been ${action == 'approve' ? 'approved' : 'rejected'} successfully.`
        );
        setTimeout(() => this.router.navigateByUrl('/'), 500);
      },
      (error: any) => {
        this.loading = false;
        this.toastService.error(
          `Error`,
          `There were some errors while ${
            action == 'approve' ? 'approving' : 'rejecting'
          } the plugin. Please contact OEC Support if the problem persists.`
        );
      }
    );
  }
  handleFileUpload(): Observable<any> {
    const newFiles = this.getNewFiles(); //files that user has selected and are not uploaded already on aws s3.

    if (newFiles.length === 0) {
      return of(true);
    }

    const preSignedUrlRequests = this.getPreSignedUrlRequests(newFiles);

    return forkJoin(preSignedUrlRequests).pipe(
      switchMap((preSignedUrls: string[]) => this.uploadFiles(newFiles, preSignedUrls)),
      catchError(this.handleError.bind(this))
    );
  }

  private getNewFiles(): any {
    const imagesFormArray = this.form.get('images') as FormArray;

    return imagesFormArray.controls.filter((imageFormGroup: FormGroup) => {
      const fileControl = imageFormGroup.get('file');
      if (fileControl && fileControl.value) {
        const fileName = fileControl.value.name;
        return !this.existingFileNames.includes(fileName);
      }
      return false;
    });
  }

  private getPreSignedUrlRequests(newFiles: FormGroup[]): Observable<string>[] {
    return newFiles.map((imageFormGroup: FormGroup) => {
      const file = imageFormGroup.get('file').value;
      const fileName = file.name;
      const folderName = this.form.get('plugin_name').value;
      return this.publishService.getPreSignedUploadUrl(folderName, fileName);
    });
  }

  private uploadFiles(newFiles: FormGroup[], preSignedUrls: string[]): Observable<any> {
    const uploadRequests = preSignedUrls.map((url: string, index: number) => {
      const file = newFiles[index].get('file').value;
      return this.publishService.uploadFile(url, file);
    });

    return forkJoin(uploadRequests);
  }

  private handleError(error: any): Observable<never> {
    this.toastService.error(
      'Error',
      'Error uploading files. Please contact support if the problem persists.'
    );
    return throwError(error);
  }

  private clearImagesFormArray() {
    const imagesFormArray = this.form.get('images') as FormArray;
    imagesFormArray.clear();
  }

  private addImagesToFormArray(images: any[]) {
    images.forEach((image: any, index: number) => {
      this.existingFileNames.push(image.title);
      const imageObject = this.createImageObjectFromData(image, index);
      this.addImageToFormArray(imageObject);
    });
  }

  private createImageObjectFromData(data: any, index: number): any {
    return {
      id: data.id,
      image_url: data.image_url || '',
      title: data.title || '',
      description: data.description || '',
      display_order: index + 1,
      is_thumbnail: data.is_thumbnail || false,
      is_mockup: data.is_mockup || false,
    };
  }

  private addImageToFormArray(imageObject: any) {
    const imagesFormArray = this.form.get('images') as FormArray;
    imagesFormArray.push(this.fb.group(imageObject));
  }
}
