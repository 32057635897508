
<div class="form-group mb-0 position-relative h-100" [formGroup]="group">
    <label [attr.for]="id">
      <ng-content select="[label]"></ng-content>
      <span class="text-danger" *ngIf="requiredMsg"> *</span>
    </label>
    <div class="form-control" [ngClass]="{ 'rounded-0': isRounded }" [ds365IsInvalid]="group.get(controlName)" 
    [ngStyle]="styles">
      <textarea
        ds365IsInvalid
        [ds365Placeholder]="placeholder"
        [ds365RequiredMsg]="requiredMsg"
        [name]="controlName"
        [formControlName]="controlName"
        [ngClass]="{'text-white': true }"
        [ngClass]="resize?'resizeable':'not-resizeable'"
        [attr.id]="id"
        [attr.rows]="rows"
        [attr.maxlength]="maxLength ? maxLength : null"
      >
      </textarea>
    </div>
    <div class="note position-absolute float-right text-muted">
      <ng-content select="[note]"></ng-content>
    </div>
    <small class="form-text text-muted"
      >&nbsp;
      <ng-content select="[hints]"></ng-content>
    </small>
  </div>