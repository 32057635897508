<div class="d-flex flex-column h-100 ms-3" [loader]="loading">
  <div class="d-flex align-items-center mt-1 mb-1 flex-grow-0">
    <div class="flex-grow-1">
      <img src="assets/icons/back.svg" style="width:10px; cursor: pointer;" (click)="back()" />
      <span class="back ms-1" (click)="back()">BACK</span>
      <span class="nav-title ms-3">{{ 'Publish your work' | uppercase }}</span>
    </div>
    <div class="flex-grow-1 d-flex justify-content-end">
      <ng-container *ngIf="!id">
        <button class="btn-primary m-2" [class.disabled]="!form.valid" [disabled]="!form.valid" (click)="finish()">
          Submit
        </button>
      </ng-container>
      <ng-container *ngIf="id">
        <button class="btn-secondary m-2" (click)="back()">
          Cancel
        </button>
        <button class="btn-secondary m-2" [class.disabled]="!form.valid || isPluginAlreadyProcessed"
          [disabled]="!form.valid || isPluginAlreadyProcessed" (click)="approveRejectModal('reject')">
          Reject
        </button>
        <button class="btn-primary m-2" [class.disabled]="!form.valid || isPluginAlreadyProcessed"
          [disabled]="!form.valid || isPluginAlreadyProcessed" (click)="approveRejectModal('approve')">
          Approve
        </button>
      </ng-container>
    </div>
  </div>
  <div class="grid flex-grow-1">
    <div class="bg-gray ps-3 pe-3 pt-4">
      <app-image-selection [form]="form"></app-image-selection>
    </div>
    <div class="bg-gray ps-3 pe-3 pt-4">
      <div class="d-flex flex-column">
        <span class="title">Publish Your Work</span>
        <span class="description mt-3">Here you can publish your work to the community.</span>
        <div class="row mt-4" [formGroup]="form">
          <div class="col-9">
            <div class="form">
              <div>
                <app-input [id]="'pluginName'" [group]="form" controlName="plugin_name"
                  requiredMsg="Plugin Name is required" [maxLength]="256" placeholder="Plugin Name"
                  [omitSpecialCharacters]="true">
                  <span class="label-default" label>Plugin Name</span>
                </app-input>
              </div>
              <div>
                <app-select [id]="'plugin-owner'" [fg]="form" [items]="allUsers"
                  [control]="$any(form.get('plugin_owner'))" optString="full_name" optKey="email"
                  noneSelectedText="Select Plugin Owner" [liveSearch]="true" [isRequired]="true" [multiple]="true"
                  [showOptionsOnSearch]="true" [searchWithOptKey]="true"
                  [liveSearchPlaceholder]="'Search users by email'">
                  <span class="label-default" label>Plugin Owner</span>
                </app-select>
              </div>
              <div class="description">
                <app-text-area [id]="'description'" [group]="form" controlName="description"
                  requiredMsg="Description is required" [maxLength]="1024" placeholder="" [styles]="{ height: '88%' }">
                  <span class="label-default" label>Description</span>
                  <ng-container note><i>Up to 1024 characters</i></ng-container>
                </app-text-area>
              </div>
              <div>
                <app-typeahead placeholder="Search company" placement="bottom-left" [data]="matchedCompanies$ | async"
                  (searchKeyword)="onSearchCompany($event)" inputFormat="name" resultFormat="name"
                  (itemSelected)="onContributorSelection($event)" [editable]="false"
                  [_model]="primaryContributorCompany">
                  <span class="label-default" label>Primary Contributor Company</span></app-typeahead>
              </div>
              <div>
                <app-select [id]="'domain'" [fg]="form" [items]="domains" [control]="$any(form.get('domain'))"
                  optString="name" optKey="name" noneSelectedText="Select Domain" [liveSearch]="true"
                  [isRequired]="true" [multiple]="true">
                  <span class="label-default" label>Domain</span>
                </app-select>
              </div>
              <div>
                <app-select [id]="'platform'" [fg]="form" [items]="filteredPlatforms"
                  [control]="$any(form.get('platform'))" optString="name" optKey="code"
                  noneSelectedText="Select Solution" [liveSearch]="true" [isRequired]="true"
                  [disabled]="$any(form.get('domain').value == '')">
                  <span class="label-default" label>Solution</span>
                </app-select>
              </div>
              <div>
                <app-select [id]="'stage'" [fg]="form" [items]="stages" [control]="$any(form.get('stage'))"
                  optString="name" optKey="code" noneSelectedText="Select Stage" [liveSearch]="false">
                  <span class="label-default" label>Stage</span>
                </app-select>
              </div>
              <div>
                <app-date-picker [id]="'release_date'" [group]="form" controlName="release_date"
                  placeholder="Release Date">
                  <span class="label-default" label>Release Date</span>
                </app-date-picker>
              </div>

              <div>
                <label for="contribution_type"><span class="label-default" label>Contribution Type</span>
                  <span class="text-danger"> *</span>
                  <app-icon class="icon mb-1 ms-2" svgName="info" svgSize="18" fill="#A9ADBF"
                    [ngbTooltip]="contributionTypeToolTipTemplate"></app-icon>
                </label>

                <div class="d-flex mt-1 align-items-center">
                  <div class="flex-grow-1 d-flex">
                    <app-checkbox [id]="'build4core'" value="true" name="tag" [group]="form" controlName="build4core"
                      [inline]="true">
                      <span class="label-default  mb-0" label>Build4Core</span>
                    </app-checkbox>
                    <div class="ms-3">
                      <app-checkbox [id]="'back2community'" value="true" [group]="form" controlName="back2community"
                        [inline]="true">
                        <span class="label-default mb-0" label>Back2Community</span>
                      </app-checkbox>
                    </div>
                    <div class="ms-3">
                      <app-checkbox [id]="'partnerplugin'" value="true" [group]="form" controlName="partnerplugin"
                        [inline]="true">
                        <span class="label-default mb-0" label>Partner Plugin</span>
                      </app-checkbox>
                    </div>
                  </div>

                  <ng-template #contributionTypeToolTipTemplate>
                    <span [innerHTML]="contributionTypeToolTip()"></span>
                  </ng-template>
                </div>
              </div>
              <div>
                <label for="priority"><span class="label-default" label>Visibility</span>
                  <span class="text-danger"> *</span>
                  <app-icon class="icon ms-2 mb-1" svgName="info" svgSize="18" fill="#A9ADBF"
                    [ngbTooltip]="visibilityToolTipTemplate"></app-icon>
                </label>

                <div class="d-flex align-items-center mt-1">
                  <div class="flex-grow-1 d-flex">
                    <app-radio-group [group]="form" id="visibility" name="visibility" [items]="[
                    {
                      name: 'Private',
                      code: 'Private'
                    },
                    {
                      name: 'Protected',
                      code: 'Protected'
                    },
                    {
                      name: 'Public',
                      code: 'Public'
                    }                     
                  ]" controlName="visibility" optLabel="name" optValue="code" [inline]="true">
                    </app-radio-group>
                  </div>
                  <ng-template #visibilityToolTipTemplate>
                    <span [innerHTML]="visibilityToolTip()"></span>
                  </ng-template>
                </div>
              </div>
              <div class="tags">
                <app-tag-input [tagsList]="tags" [charLimit]="25" (tagsChange)="tagsChanged($event)"
                  [values]="form.controls['tags'].value">
                  <span class="label-default" label>Tags</span>
                  <ng-container note><i>Up to 5 tags</i></ng-container>
                </app-tag-input>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #approveRejectPlugin let-modal>
    <div class="modal-header pl-30">
      <h4 class="modal-title" id="modal-basic-title">{{modalStatus | titlecase}} Plugin</h4>
      <button type="button" class="close cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2rem">
      <div class="content-body d-flex justify-content-center align-items-center flex-column text-center">
        <div class="notifications">Are you sure you want to {{modalStatus}} the plugin?</div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button class="btn-secondary me-3" (click)="modal.dismiss()">Cancel</button>
        <button class="btn-primary cursor-pointer" (click)="modal.close('confirm')">
          Yes
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #pluginAlreadyProcessed let-modal>
    <div class="modal-header pl-30">
      <h4 class="modal-title" id="modal-basic-title">{{modalStatus | titlecase}} Plugin</h4>
      <button type="button" class="close cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2rem">
      <div class="content-body d-flex justify-content-center align-items-center flex-column text-center">
        <div class="notifications" *ngIf="modalStatus == 'approved'">The plugin's approval has already been granted.
        </div>
        <div class="notifications" *ngIf="modalStatus == 'rejected'">The plugin has already been declined.</div>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <button class="btn-primary cursor-pointer" (click)="modal.close('ok')">
          OK
        </button>
      </div>
    </div>
  </ng-template>